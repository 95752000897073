import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Title from './Title'

import f1 from '../assets/img/features/f001.svg'
import f2 from '../assets/img/features/f002.svg'
import f3 from '../assets/img/features/f003.svg'

function Features() {
  return (
    <div className="section bg-lite" id="feature_block">
      <Title
        type="title c-premium"
        text="Shipping made easy"
      />
      <div className="container">
        <h2 className="subtitle w-60 ma text-center c-dark" style={{ fontWeight: '400' }}>B2B Delivery is an all-in-one platform that lets you manage your shipping needs and data in one place. We handle everything from logistics to courier services, as well as all the necessary documentation, so you can focus on what matters — your business.
        </h2>
        <div className="s-100"></div>
        <div className="s-20"></div>
        <div className="w-80 ma flex jcsb aic p-5 bg-white shadow-sm">
          <div className="w-30 p-5">
            <div className="p-4"><img src={f1} alt="" /></div>
          </div>
          <div className="w-60">
            <h3 className="subtitle c-theme text-left">A complete tool with a single solution</h3>
            <p className="text text-left" style={{ fontWeight: '400' }}>We're more than just a shipper or courier service — we're a one-stop shop for shipping needs, with a complete suite of logistics and e-commerce functionalities. Our products cover it all, from warehousing to post office and courier services, helping to provide seamless customer service and operations - all at an affordable price!</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="w-80 ma flex jcsb aic p-5 bg-white shadow-sm">
          <div className="w-60">
            <h3 className="subtitle c-theme text-right">Integrated into your system</h3>
            <p className="text text-right" style={{ fontWeight: '400' }}>We offer integrations with popular CRM platforms such as SugarCRM & Salesforce so you don't have to worry about losing any customer data when you switch services. With our integrations, everything is never lost and always accessible.</p>
          </div>
          <div className="w-30 p-5">
            <div className="p-4"><img src={f2} alt="" /></div>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="w-80 ma flex jcsb aic p-5 bg-white shadow-sm">
          <div className="w-30 p-5">
            <div className="p-4"><img src={f3} alt="" /></div>
          </div>
          <div className="w-60">
            <h3 className="subtitle c-theme text-left">Logistics on demand</h3>
            <p className="text text-left" style={{ fontWeight: '400' }}>When your business needs something delivered, you have to ensure that customers are getting the service they deserve. Ensure your customer's trust and satisfaction with our one-stop solution for all logistics needs: from ordering to delivery.</p>
          </div>
        </div>
      </div>
      <div className="s-80"></div>
      <div className="text-center">
        <AnchorLink href="#contact_block" className='btn btn--premium btn--big'>Start Now</AnchorLink>
      </div>
    </div>
  )
}

export default Features
