import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Footer() {
  return (
    <footer className="footer" id="footer_block">
      <div className="py-5">
        <div className="f-bottom py-5">
          <div className="container text-center">
            <div className="w-90 ma flex jcc aic">
              <p className="text text-left c-premium w-50"><AnchorLink className="c-premium" href="#root">B2B Delivery</AnchorLink> - &copy;2022. All Rights Reserved</p>
              <div id="info" className="w-50 text-right">
                <AnchorLink className="text c-premium mx-4" href="#info">Privacy Policy</AnchorLink>
                <AnchorLink className="text c-premium mx-4" href="#info">Terms of Use</AnchorLink>
                <AnchorLink className="text c-premium ml-4" href="#contact_block">Support</AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
