import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function Intro() {

  return (
    <div className="intro">
      <div className="flex jcc aic w-100 h-100">
        <div>
          <p className="intro__subtitle text-center">Streamline your logistics today!
          </p>
          <div className="s-20"></div>
          <h2 className="intro__title font-bold text-center">
            We help businesses track and manage their global supply chains
          </h2>
          <div className="s-40"></div>
          <div className="flex jcc au">
            <AnchorLink href="#info_block"><span className="btn mx-4">How it work</span></AnchorLink>
            <AnchorLink href="#contact_block"><span className="btn btn--premium mx-4">Connect Now</span></AnchorLink>
          </div>
        </div>
      </div>
    </div >
  )
}
