import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'


function Cta() {
  return (
    <div className="section" id="cta_block">
      <div className="flex aic jcc">
        <div className="text-center">
          <div className="s-100"></div>
          <div className="s-40"></div>
          <p className="c-white w-100 title" style={{ textTransform: 'none' }}>Integrate your business to B2B Delivery</p>
          <div className="s-20"></div>
          <AnchorLink href="#contact_block" className='btn btn--big btn--premium btn--big'>Start Now</AnchorLink>
        </div>
      </div>
    </div>
  );
}

export default Cta
