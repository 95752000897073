import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import pic1 from '../assets/img/partners/p001.png'
import pic2 from '../assets/img/partners/p002.png'
import pic3 from '../assets/img/partners/p003.png'
import pic4 from '../assets/img/partners/p004.png'
import pic5 from '../assets/img/partners/p005.png'
import pic6 from '../assets/img/partners/p006.png'
import pic7 from '../assets/img/partners/p007.png'
import pic8 from '../assets/img/partners/p008.png'
import pic9 from '../assets/img/partners/p009.png'
import pic10 from '../assets/img/partners/p010.png'


function Partners() {

  const slides = [
    {
      id: 1,
      pic: pic1
    },
    {
      id: 2,
      pic: pic2
    },
    {
      id: 3,
      pic: pic3
    },
    {
      id: 4,
      pic: pic4
    },
    {
      id: 5,
      pic: pic5
    },
    {
      id: 6,
      pic: pic6
    },
    {
      id: 7,
      pic: pic7
    },
    {
      id: 8,
      pic: pic8
    },
    {
      id: 9,
      pic: pic9
    },
    {
      id: 10,
      pic: pic10
    }
  ]

  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='mx-5'>
        <Slider {...settings}>
          {slides.map(screen => (
            <AnchorLink href="#partners_block">
              <img
                width={120}
                src={screen.pic}
                alt={'partner'}
              />
            </AnchorLink>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <div id="partners_block">
      <div className="w-80 ma pl-5" style={{ margin: '-300px auto 70px' }}><Gallery /></div>
    </div>
  );
}

export default Partners
