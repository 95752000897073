import React from 'react'

import {
  HiAdjustmentsHorizontal,
  HiOutlineClipboardDocumentCheck,
  HiOutlineCreditCard,
  HiOutlineUserGroup,
  HiOutlineMap,
  HiOutlineLightBulb,
  HiOutlineKey,
  HiOutlineCircleStack,
  HiOutlineShoppingCart,
  HiOutlineBuildingLibrary
} from "react-icons/hi2"

import Title from './Title'

function Info() {
  return (
    <div id="info_block">
      <div className="s-40"></div>
      <div className="s-100"></div>
      <Title
        type="title"
        text="How We Can Help"
      />
      <div className="s-40"></div>
      <div className="flex w-100 h-60 jcsa aic">
        <div className="p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiAdjustmentsHorizontal size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Supply chains running smoothly.</h3>
          <p className="text px-3 mx-4">Supply chains are the backbone of every successful business. Businesses need to ensure that the supply chain is running smoothly to maintain a competitive advantage. Supply chain management experts will work with you to make sure your supply chain is running at peak efficiency and has sufficient capacity.</p>
        </div>
        <div className="p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineClipboardDocumentCheck size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Automate docs flow.</h3>
          <p className="text px-3 mx-4">Automating document flow for your business can be a tedious and time-consuming process. However, it is necessary to keep employees up-to-date with important information like meeting minutes, deadlines, recent developments and more.</p>
        </div>
        <div className="p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineCreditCard size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Automate and streamline purchasing processes.</h3>
          <p className="text px-3 mx-4">The purchasing process is a long and tedious process, fraught with a number of different risks. From the purchase to the delivery, mistakes are made that can have dire consequences for any business. The process from beginning to end is cumbersome and time consuming, usually involving a lot of back and forth between various departments. And then there's the cost of mistakes, which can be exorbitant.</p>
        </div>
      </div>
      <div className="flex w-100 h-55 jcsa aic">
        <div className="p-5 h-45 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineUserGroup size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Manage and optimize stock levels to meet customer demands.</h3>
          <p className="text px-3 mx-4">A business' most valuable asset is its inventory. The purpose of inventory management is to ensure that a company has the right product, at the right time and at the right place. The goal is to maintain an optimum level of stock that satisfies customer demands while not creating excess costs or losses due to product obsolescence.</p>
        </div>
        <div className="p-5 h-45 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineMap size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Monitor and track inventory in real time.</h3>
          <p className="text px-3 mx-4">Keeping tabs on inventory is an important part of any business. This helps to prevent stock-outs and keep products moving. There are a variety of tools for monitoring inventory such as bar code scanning and RFID tracking. Choosing the right system can help optimize the flow of products through your supply chain.</p>
        </div>
      </div>
      <div className="flex w-100 h-60 jcsa aic">
        <div className="w-30 p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineLightBulb size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Leverage analytics and predictive insights to increase efficiency.</h3>
          <p className="text px-3 mx-4">Analytics and predictive insights are two of the most powerful tools to discover patterns in data. This helps to understand what customers want and need. With this knowledge, marketers can improve their campaigns and provide better products or services.</p>
        </div>
        <div className="w-40 p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineKey size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Secure and manage customer data with customer relationship management (CRM) tools.</h3>
          <p className="text px-3 mx-4">Businesses are collecting more and more data on their customers, but now they need to securely store it. They need to make sure that if someone hacks one of their databases, all of the customer data isn't compromised. CRM tools allow businesses to manage this information for many different aspects of their company, including sales, marketing and customer service.</p>
        </div>
        <div className="w-30 p-5 h-55 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineCircleStack size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Utilize big data insights to gain a competitive advantage.</h3>
          <p className="text px-3 mx-4">In today's data driven world it is imperative to use insights from big data to gain a competitive advantage.</p>
        </div>
      </div>
      <div className="flex w-100 h-60 jcsa aic">
        <div className="p-5 h-50 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineShoppingCart size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Streamline the payment process with integrated payment gateways.</h3>
          <p className="text px-3 mx-4">Integrated payment gateways are an excellent resource for any organization. They allow users to pay for goods and services through a standard process with a number of different methods. In this article, we will cover how integrated payment gateways work and why they are valuable</p>
        </div>
        <div className="p-5 h-50 m-4" style={{ border: '1px solid #efeeff', borderTop: '2px solid #14C7FF' }}>
          <div className="mx-4"><HiOutlineBuildingLibrary size={24} color={'#0E1D2E'} /></div>
          <h3 className="subtitle mx-4">Enable cross-border transactions with international banking compliance.</h3>
          <p className="text px-3 mx-4">The team of experts at Debitum are ensuring that clients in developing countries can take advantage of the newest technologies to broaden their reach. With Debitum, they can now offer their clients a range of top-tier financial services: from international banking compliance and P2P lending to loans and currency exchange.</p>
        </div>
      </div>
      <div className="s-40"></div>
      <div className="s-100"></div>
    </div>
  )
}

export default Info
