import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import s1 from '../assets/img/screens/001.png'
import s2 from '../assets/img/screens/002.png'
import s3 from '../assets/img/screens/003.png'
import s4 from '../assets/img/screens/004.png'
import s5 from '../assets/img/screens/005.png'

import admin from '../assets/img/content/dashboard.png'


function Demo() {

  const slides = [
    {
      id: 1,
      pic: s1,
      title: 'Application'
    },
    {
      id: 2,
      pic: s2,
      title: 'Home Screen'
    },
    {
      id: 3,
      pic: s3,
      title: 'Tracking Screen'
    },
    {
      id: 4,
      pic: s4,
      title: 'Tracking Maps Screen'
    },
    {
      id: 5,
      pic: s5,
      title: 'Maps Screen'
    }
  ]

  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: true,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        {slides.map(screen => (
          <>
            <img
              className='w-100'
              src={screen.pic}
              alt={screen.title}
            />
          </>
        ))}
      </Slider>
    );
  }

  return (
    <div id="demo_block">
      <div className="flex rowrap jcsb aic w-100 h-160">
        <div className="w-50 flex jcc aic bg-premium h-160" style={{ margin: 0, padding: 0, float: 'left' }}>
          <div className="w-80 ma">
            <div className="mx-5 px-5"><h3 className="subtitle c-white text-center">Secure and manage customer data with customer relationship management (CRM) tools.</h3></div>
            <div className="s-60"></div>
            <img src={admin} alt={'Dashboard'} className="w-100" />
            <div className="s-40"></div>
            <p className="text c-white text-center px-5 mx-5">Businesses are collecting more and more data on their customers, but now they need to securely store it. They need to make sure that if someone hacks one of their databases, all of the customer data isn't compromised. CRM tools allow businesses to manage this information for many different aspects of their company, including sales, marketing and customer service.</p>
          </div>
        </div>
        <div className="w-50 bg-lite flex jcc aic h-160" style={{ margin: 0, padding: 0, float: 'left' }}>
          <div className="w-80 ma">
            <div className="s-30"></div>
            <div>
              <div className="mx-5 px-5"><h3 className="subtitle text-center mx-5 px-5">Leverage analytics and predictive insights to increase efficiency</h3></div>
              <p className="text text-center">Analytics and predictive insights are two of the most powerful tools to discover patterns in data. This helps to understand what customers want and need. With this knowledge, marketers can improve their campaigns and provide better products or services.</p>
            </div>
            <Gallery />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo
