import React from 'react'

import pic from '../assets/img/content/map.svg'

function About() {
  return (
    <div className="section" id="about_block">

      <div className="w-80 ma">
        <h2 className='title'>About Us</h2>
      </div>
      <div className="s-40"></div>
      <div className="s-40"></div>
      <div className="container">
        <div className="flex w-90 ma jcsa aic">
          <div className="w-40">
            <img src={pic} alt="pic" className='al' />
            <div className="s-20"></div>
            <p className="subtitle c-theme text-left al">We are leading provider of cloud-based logistics management software.</p>
            <p className="text c-middle text-left al">Logistics Cloud is the world's leading provider of cloud-based logistics management software. We help businesses track and manage their global supply chains, providing them with visibility and cost savings across their entire operation.</p>
          </div>
          <div className="w-40">
            <p className="subtitle c-theme text-left ar">Delivery process will require unique research</p>
            <p className="text c-middle text-left ar">The logistics cloud is an application that provides a variety of optimizations for the delivery process. Creating a logistics cloud will require unique research like collecting and analyzing data to make decisions. A logistics cloud has the potential to save companies tons of money, reduce carbon footprint and increase customer satisfaction by delivering packages more efficiently and quickly.</p>
            <div className="s-10"></div>
            <p className="subtitle c-theme text-left ar">We review your warehousing and distribution needs</p>
            <p className="text c-middle text-left ar">Logistics is an essential part of any business. It is the process of moving goods from one place to another in order for final use. For this, you need a well-designed warehousing and distribution system that will enable your company to make good use of its resources and provide a quality customer service. The experts at Logisticscloud.com can help you with everything from analyzing your business needs to creating</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
