import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '../logo.svg'

function Menu(props) {
  return (
    <div className="hb-header au">
      <h1 className="hb-logo">
        <AnchorLink className="hb-logo__link scroll" href="#root">
          <img src={Logo} width={64} alt="logo" style={{ marginTop: 20 }} />
          <h1 className="hb-logo__text">B2B Delivery<br /><span>Smart Cloud</span></h1>
        </AnchorLink>
      </h1>
      <div className={`hb-menu ${props.mobile}`}>
        <AnchorLink className="hb-menu__link" href="#about_block">About</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#info_block">How It Works</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#feature_block">Features</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#contact_block">Contact</AnchorLink>
        <AnchorLink className="btn" href="#contact_block">Start Now</AnchorLink>
      </div>
    </div>
  );
}

export default Menu
